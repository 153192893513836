
import Vue from "vue";
import { Route } from "vue-router";

import http from "@/http";
import { Activity, Breadcrumb } from "@/interfaces";

export interface DataType {
  activity?: Activity;
}

export default Vue.extend({
  data(): DataType {
    return {
      activity: undefined
    };
  },
  computed: {
    breadcrumbs(): Breadcrumb[] {
      if (this.activity == null || this.activity.owner == null) {
        return [];
      }

      return [
        {
          text: "ホーム",
          disabled: false,
          to: "/"
        },
        {
          text: "オーナー",
          disabled: false,
          to: "/owners"
        },
        {
          text: `# ${this.activity.owner.id} ${this.activity.owner.name}`,
          disabled: false,
          to: `/owner/${this.activity.owner.id}`
        },
        {
          text: `活動`,
          disabled: true
        }
      ];
    }
  },
  async beforeRouteEnter(to: Route, from: Route, next: any) {
    const url = `/activities/${to.params.id}`;

    const { data } = await http.get<Activity>(url);

    next((vm: Vue & DataType) => {
      vm.activity = data;
    });
  }
});
